import { strings } from 'res';
import { CashHandoverIcon, CommandCenterIcon, ReportIcon, DashboardIcon, SettingIcon, SLAIcon, ProductIcon } from "../assets/svg/icon";

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Projects",
      path: strings.navigation.path.surat_masuk,
      title: "Projects",
      icon: CommandCenterIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Progress",
      path: strings.navigation.path.surat_keluar,
      title: "Progress",
      icon: SLAIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Kontraktor",
      path: strings.navigation.path.kontraktor,
      title: "Kontraktor",
      icon: ProductIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Laporan",
      path: strings.navigation.path.laporan,
      title: "Laporan",
      icon: ReportIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pengguna",
      path: strings.navigation.path.pengguna,
      title: "Pengguna",
      icon: CashHandoverIcon,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const ControlTree = [{
  key: "Pengaturan",
  path: strings.navigation.path.settings,
  title: "Pengaturan",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingIcon,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;