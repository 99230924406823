import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import { fetchAllSurat, fetchAllByTanggalFilter } from 'redux/features/surat';
import CsvDownloadButton from 'react-json-to-csv'
import { exportToExcel } from 'react-json-to-excel';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const LAPORAN = () => {
    const history = useHistory()
    const [isLoading,setLoading] = useState(true)
    const dispatch = useDispatch()
    const { Search } = Input
    const [startDateState,setStartDate] = useState(null)
    const [endDateState,setEndDate] = useState(null)

    const {
        list,
        selectedRows,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.surat)

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllByTanggalFilter({
                startDate:moment().format("YYYY-MM-DD"),
                endDate:moment().format("YYYY-MM-DD")
            })).unwrap()
            setLoading(false)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    const getDataWithTanggal = useCallback(async (startDate,endDate) => {
        try {
            await dispatch(fetchAllByTanggalFilter({
                startDate:startDate,
                endDate:endDate
            })).unwrap()
            setLoading(false)
        } catch (error) {
            message.error('Gagal mencari data pastikan kedua tanggal sudah terisi!')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'Nomor Project',
            dataIndex: 'nomorSurat',
            key: 'nomorSurat',
        },
        {
            title: 'Perihal Project',
            dataIndex: 'judulSurat',
            key: 'judulSurat',
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            render: (_, record) => {
                return (
                    moment(record.tanggalSurat).format("YYYY-MM-DD")
                )
            }
        },
        {
            title: 'Actual',
            dataIndex: 'actual',
            key: 'actual',
            render: (_, record) => {
                {
                    if (record.actual === "BELUM") {
                        return (
                            <h5 style={{ color: "red" }}>BELUM</h5>
                        )
                    } else {
                        return (
                            <h5 style={{ color: "green" }}>SUDAH</h5>
                        )
                    }
                }
            },
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            render: (_, record) => {
                {
                    if (record.plan === "BELUM") {
                        return (
                            <h5 style={{ color: "red" }}>BELUM</h5>
                        )
                    } else {
                        return (
                            <h5 style={{ color: "green" }}>SUDAH</h5>
                        )
                    }
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'statusSurat',
            render: (_, record) => {
                {
                    if (record.statusMaterai === "BELUM") {
                        return (
                            <h5 style={{ color: "red" }}>PROGRESS</h5>
                        )
                    } else {
                        return (
                            <h5 style={{ color: "green" }}>SELESAI</h5>
                        )
                    }
                }
            },
        },
        {
            title: 'File',
            dataIndex: 'externalFile',
            render: (_, record) => {
                return (
                    <a target='_blank' href={record.externalFile}>Cek File</a>
                )
            },
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'status',
            render: (_, record) => (
                <div className="text-center">
                    <a type="primary" style={{ width: "70%" }} onClick={() => {
                        history.push({
                            pathname: '/app/detail-surat-masuk',
                            id: record._id,
                        })
                    }} >Detail</a>
                </div>
            ),
        },
    ];

    const onSearch = (value) => console.log(value);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Project di Tanggal: {moment().format("DD-MM-YYYY")}</h2>
                    <p>Semua project yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Search
                                placeholder="Cari Berdasarkan Perihal"
                                onSearch={onSearch}
                                style={{
                                    width: "49%",
                                    marginRight: "2%"
                                }}
                            />
                            <DatePicker
                                onChange={(value)=>{
                                    setStartDate(moment(value).format("YYYY-MM-DD"))
                                }}
                            style={{
                                width: "24%",
                                marginRight: "2%"
                            }} placeholder='Tanggal Mulai' />
                            <DatePicker
                                onChange={(value)=>{
                                    setEndDate(moment(value).format("YYYY-MM-DD"))
                                }}
                            style={{
                                width: "24%",
                                marginRight: "2%"
                            }} placeholder='Tanggal Selesai' />
                             <Button type="primary" onClick={() => {getDataWithTanggal(startDateState,endDateState)}}>Cari</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            dataSource={list}
                            rowKey='id'
                            pagination={{ pageSize: 5 }} 
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px",width:"100%" }} onClick={() => exportToExcel(list, 'report' )}>Download as Excel</Button>
                </Col>
            </Row>
            <br></br>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <CsvDownloadButton style={{ width: "100%", padding: "3px" }} data={list}>Download as CSV</CsvDownloadButton>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(LAPORAN);
